<template>
  <div class="mx-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button
          class="btn-create"
          type="button"
          variant="lisaweb"
          size="sm"
          @click="onRefreshContent(filterName)"
          title="Refresh"
          ><b-icon icon="arrow-clockwise" aria-hidden="true"></b-icon
          >Ricarica</b-button
        >
      </b-col>
    </b-row>
    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-input
                name="title"
                type="text"
                label="Report"
                v-model="filter.byReport.title"
                placeholder="Inserisci un titolo report"
              />
            </div>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>
    <generated-reports
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterOn="{
        byRelations: ['byReport', 'byDocument'],
      }"
      :filterName="filterName"
      :ref="tableRef"
      descending
      sortField="created_at"
      @report="downloadReport"
    ></generated-reports>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import DocumentsMixin from "@/mixins/DocumentsMixin";
import GeneratedReports from "@/components/tables/GeneratedReports.vue";
import { mapActions } from "vuex";
import BaseInput from "@/components/form/BaseInput";
import { toLocaleDate } from "@/utils/dates";

export default {
  mixins: [CustomFiltersMixin, DocumentsMixin],
  data() {
    return {
      repository: "generated_report",
      resource: "generated_reports",
      // reportsAvailable: ["INCAPAGA"],
      tableRef: "AccountingGeneratedReportFilter",
      filterName: "filterAccountingGeneratedReport",
      filter: this.initFilter(),
      fields: [
        {
          key: "report.title",
          label: this.getDictionary("formatted_title", "generated_report"),
        },
        {
          key: "report.code",
          label: this.getDictionary("code", "generated_report"),
        },
        {
          key: "format",
          label: this.getDictionary("format", "generated_report"),
        },
        {
          key: "status_report.text",
          label: this.getDictionary("status_report", "generated_report"),
        },
        {
          key: "is_ready.text",
          label: this.getDictionary("is_ready", "generated_report"),
        },
        {
          key: "user",
          label: this.getDictionary("username", "user"),
        },
        {
          key: "created_date",
          label: this.getDictionary("created_at"),
          sortable: true,
          formatter: (value) => toLocaleDate(value, "DD/MM/YYYY HH:mm:ss"),
          sortKey: "created_at",
        },
        {
          key: "download",
          label: this.getDictionary("download", "generated_report"),
          tdClass: "text-center",
          thStyle: {
            width: "125px",
          },
        },
      ],
    };
  },
  components: {
    GeneratedReports,
    BaseInput,
  },
  methods: {
    downloadReport(document) {
      this.downloadDocument(document);
    },
    initFilter() {
      let init = {
        byAttribute: {
          format: null,
        },
        byReport: {
          title: null,
          code: null, // TODO: to restrict viewable reports, set 'em here! ["INCAPAGA"],
        },
      };
      return init;
    },
    onRefreshContent(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.$refs[this.tableRef].fetch();
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
  },
};
</script>
