var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-1" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c("div", [
                _vm.showForm
                  ? _c(
                      "div",
                      [
                        _vm.reportOptions.length
                          ? _c(
                              "div",
                              { staticClass: "flex-container mt-2" },
                              _vm._l(
                                _vm.reportOptions,
                                function (report, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "flex-item" },
                                    [
                                      _c("div", { staticClass: "mt-4" }, [
                                        _vm._v(_vm._s(report.text)),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "button",
                                          attrs: {
                                            squared: "",
                                            type: "submit",
                                            variant: "lisaweb",
                                            size: "lg",
                                            disabled: report.disabled,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onClickReport(
                                                report.value
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              Crea Report\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _c("div", [_vm._v("Nessun report disponibile")]),
                        _vm._v(" "),
                        _c("submit-report-modal", {
                          attrs: {
                            code: _vm.reportCode,
                            reportId: _vm.reportId,
                            beFormCreate: _vm.beCreateForm,
                            beRulesCreate: _vm.beRulesCreate,
                            allowedFormatOptions: _vm.allowed,
                          },
                          on: { "save-report": _vm.saveReport },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }